// extracted by mini-css-extract-plugin
export var arrow = "bg_5";
export var body = "bg_c";
export var circle = "bg_dJ";
export var customer = "bg_dH";
export var feedback = "bg_dG";
export var feedbacks = "bg_dD";
export var footer = "bg_c1";
export var footerText = "bg_cL";
export var left = "bg_bR";
export var nameLocation = "bg_dK";
export var padding = "bg_dr";
export var right = "bg_bT";
export var section = "bg_d";
export var selected = "bg_bb";
export var selectors = "bg_dC";
export var subtitle = "bg_b3";
export var text = "bg_cj";
export var title = "bg_h";
export var wrapper = "bg_dF";