// extracted by mini-css-extract-plugin
export var active = "bw_8";
export var categories = "bw_cN";
export var category = "bw_cP";
export var description = "bw_db";
export var featured = "bw_cS";
export var icon = "bw_br";
export var input = "bw_cy";
export var mobileVidContainer = "bw_fR";
export var mobileVideo = "bw_fS";
export var mobileVideoThumbnail = "bw_fT";
export var mobileVideoTitle = "bw_fV";
export var mobileVidsContainer = "bw_fQ";
export var moreVideo = "bw_fL";
export var moreVideoContainer = "bw_fK";
export var moreVideoThumbnail = "bw_fM";
export var moreVideoTitle = "bw_fN";
export var moreVideos = "bw_fB";
export var moreVideosHeader = "bw_fz";
export var moreVideosSlider = "bw_fJ";
export var moreVideosSliderMobile = "bw_fH";
export var scrollArrow = "bw_fD";
export var scrollButton = "bw_fC";
export var scrollLeft = "bw_fF";
export var scrollRight = "bw_fG";
export var search = "bw_cx";
export var section = "bw_d";
export var sectionTitle = "bw_fy";
export var selector = "bw_fP";
export var selectors = "bw_dC";
export var video = "bw_fx";
export var videoContainer = "bw_fw";