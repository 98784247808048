// extracted by mini-css-extract-plugin
export var bottomContainer = "Q_cH";
export var buttons = "Q_cc";
export var icon = "Q_br";
export var link = "Q_bq";
export var section = "Q_d";
export var social = "Q_cG";
export var text = "Q_cj";
export var title = "Q_h";
export var topContainer = "Q_cF";
export var videoContainer = "Q_cC";
export var videoWrapper = "Q_cD";