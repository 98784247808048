// extracted by mini-css-extract-plugin
export var bottomWave = "bx_cK";
export var button = "bx_cm";
export var buttonContainer = "bx_b9";
export var buttonFooter = "bx_fW";
export var container = "bx_b";
export var middleWave = "bx_cJ";
export var section = "bx_d";
export var subtitle = "bx_b3";
export var textContainer = "bx_bY";
export var title = "bx_h";