// extracted by mini-css-extract-plugin
export var buttons = "bl_cc";
export var container = "bl_b";
export var content = "bl_bx";
export var icon = "bl_br";
export var iconContainer = "bl_b0";
export var image = "bl_g";
export var section = "bl_d";
export var selected = "bl_bb";
export var tab = "bl_fd";
export var tabText = "bl_fc";