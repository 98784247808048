// extracted by mini-css-extract-plugin
export var answer = "bz_gj";
export var arrow = "bz_5";
export var body = "bz_c";
export var category = "bz_cP";
export var contactIcons = "bz_gb";
export var current = "bz_fh";
export var fade = "bz_gf";
export var footer = "bz_c1";
export var footerText = "bz_cL";
export var icon = "bz_br";
export var iconContainer = "bz_b0";
export var issue = "bz_gh";
export var issueContainer = "bz_gd";
export var issueText = "bz_gg";
export var issues = "bz_gc";
export var left = "bz_bR";
export var padding = "bz_dr";
export var right = "bz_bT";
export var section = "bz_d";
export var selectors = "bz_dC";
export var subtitle = "bz_b3";
export var title = "bz_h";