// extracted by mini-css-extract-plugin
export var buttons = "X_cc";
export var column = "X_b2";
export var columns = "X_b1";
export var container = "X_b";
export var down = "X_c6";
export var download = "X_c5";
export var footer = "X_c1";
export var information = "X_c4";
export var section = "X_d";
export var show = "X_bj";
export var text = "X_cj";
export var title = "X_h";
export var units = "X_c7";
export var up = "X_c9";
export var upload = "X_c8";
export var waveOne = "X_q";
export var waveThree = "X_c3";
export var waveTwo = "X_n";
export var waves = "X_c2";