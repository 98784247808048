// extracted by mini-css-extract-plugin
export var active = "bF_8";
export var categories = "bF_cN";
export var category = "bF_cP";
export var icon = "bF_br";
export var icons = "bF_bs";
export var image = "bF_g";
export var member = "bF_gL";
export var memberTitle = "bF_gM";
export var name = "bF_fp";
export var placeholder = "bF_6";
export var section = "bF_d";
export var show = "bF_bj";
export var subtitle = "bF_b3";
export var team = "bF_gK";