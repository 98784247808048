// extracted by mini-css-extract-plugin
export var appStores = "Z_dw";
export var grey = "Z_dx";
export var icon = "Z_br";
export var iconGroup = "Z_dv";
export var image = "Z_g";
export var left = "Z_bR";
export var reverse = "Z_dt";
export var right = "Z_bT";
export var section = "Z_d";
export var textSection = "Z_ds";