// extracted by mini-css-extract-plugin
export var buttons = "bj_cc";
export var container = "bj_b";
export var image = "bj_g";
export var innerText = "bj_dT";
export var logo = "bj_bk";
export var p = "bj_dV";
export var section = "bj_d";
export var short = "bj_dW";
export var tall = "bj_dX";
export var textContainer = "bj_bY";