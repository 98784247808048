// extracted by mini-css-extract-plugin
export var background = "bs_z";
export var backgroundContainer = "bs_fk";
export var bottomImage = "bs_t";
export var bottomImageContainer = "bs_fn";
export var buttons = "bs_cc";
export var container = "bs_b";
export var imagesContainer = "bs_fm";
export var middleWave = "bs_cJ";
export var section = "bs_d";
export var text = "bs_cj";
export var textContainer = "bs_bY";
export var title = "bs_h";
export var topImage = "bs_m";
export var waveOverlay = "bs_fl";