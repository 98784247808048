// extracted by mini-css-extract-plugin
export var background = "bK_z";
export var backgroundContainer = "bK_fk";
export var bottomWave = "bK_cK";
export var buttons = "bK_cc";
export var container = "bK_b";
export var content = "bK_bx";
export var icon = "bK_br";
export var iconContainer = "bK_b0";
export var middleWave = "bK_cJ";
export var negativeTop = "bK_g0";
export var overlap = "bK_g2";
export var section = "bK_d";
export var selected = "bK_bb";
export var tab = "bK_fd";
export var tabText = "bK_fc";
export var title = "bK_h";
export var topWave = "bK_g1";