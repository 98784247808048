// extracted by mini-css-extract-plugin
export var container = "bG_b";
export var feature = "bG_gP";
export var featureText = "bG_gQ";
export var features = "bG_gN";
export var footer = "bG_c1";
export var footerText = "bG_cL";
export var icon = "bG_br";
export var section = "bG_d";
export var text = "bG_cj";
export var title = "bG_h";
export var waveOne = "bG_q";
export var waveThree = "bG_c3";
export var waveTwo = "bG_n";
export var waves = "bG_c2";