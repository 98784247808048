// extracted by mini-css-extract-plugin
export var buttons = "bC_cc";
export var left = "bC_bR";
export var media = "bC_gH";
export var right = "bC_bT";
export var section = "bC_d";
export var selected = "bC_bb";
export var signature = "bC_gG";
export var testimonial = "bC_gF";
export var text = "bC_cj";
export var textContainer = "bC_bY";
export var video = "bC_fx";