// extracted by mini-css-extract-plugin
export var container = "N_b";
export var content = "N_bx";
export var icon = "N_br";
export var iconContainer = "N_b0";
export var icons = "N_bs";
export var image = "N_g";
export var label = "N_b5";
export var section = "N_d";
export var text = "N_cj";
export var title = "N_h";